import React from "react";
import {Grid} from "@mui/material";
import {FandomBody} from "./fandom-body/FandomBody";
import {FandomSidebar} from "./fandom-sidebar/FandomSidebar";

export function FandomComponent(props) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={5} lg={4} xl={3}>
				<FandomSidebar/>
			</Grid>

			<Grid item xs={12} md={7} lg={8} xl={9}>
				<FandomBody/>
			</Grid>
		</Grid>
	)
}
