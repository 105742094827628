import React from "react";
import Typography from "@mui/material/Typography";
import {Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, createStyles, List, ListItem,} from "@mui/material";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {makeStyles} from '@mui/styles'
import {DashboardSidebarCalendar} from "../dashboard-sidebar-calendar/DashboardSidebarCalendar";

const accordionStyles = makeStyles((theme) => {
	createStyles({
		body: {
			width: '100%',
			backgroundColor: 'transparent',
			boxShadow: 'none'
		}
	})
})

export function DashboardSidebarPerformances() {
	const classes = accordionStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const label = {inputProps: {'aria-label': 'Checkbox demo'}};


	return (
		<Box>
			<List>
				<ListItem disableGutters>
					<Accordion
						expanded={expanded === 'panel1'}
						onChange={handleChange('panel1')}
						className={classes.body}
					>
						<AccordionSummary
							expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography sx={{flexShrink: 0}} component={'h5'} className={'section-title'}>
								Performances (2)
							</Typography>
						</AccordionSummary>

						<AccordionDetails>
							<div>
								<Checkbox {...label} defaultChecked size="small"/> House of Blues - June 1 st , 2020
							</div>
							<div>
								<Checkbox {...label} defaultChecked size="small"/> Facebook Live - April 15 th , 2020
							</div>
							<div className={'divider'}/>
							<DashboardSidebarCalendar/>
						</AccordionDetails>
					</Accordion>
				</ListItem>

				<ListItem>
					<div className={'advertisement'}>
						<img src={'/images/hero3.jpg'} alt={'hero3'}/>
						<div className={'advertisement-text'}>
							<p className={'h5 text-primary'}>INVITE OTHER ARTISTS AND</p>
							<p className={'h5 text text-light'}>START EARNING MORE!</p>
						</div>
					</div>
				</ListItem>
			</List>
		</Box>
	)
}
