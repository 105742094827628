import React from "react";
import {Grid} from "@mui/material";
import {AwardsBody} from "./awards-body/AwardsBody";
import {AwardsSidebar} from "./awards-sidebar/AwardsSidebar";

export function AwardsComponent(props) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={5} lg={4} xl={3}>
				<AwardsSidebar/>
			</Grid>

			<Grid item xs={12} md={7} lg={8} xl={9}>
				<AwardsBody/>
			</Grid>
		</Grid>
	)
}
