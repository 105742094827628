import React from "react";
import './Sidebar.scss'
import {DashboardSidebarPerformances} from "./components/dashboard-sidebar-performances/DashboardSidebarPerfonmances";
export const DashboardSidebar = () => {
  return (
    <div className="DashboardSidebar">
      <DashboardSidebarPerformances/>
    </div>
  )
}
