const DISPLAY = true;
const BORDER = true;
const CHART_AREA = true;
const BORDER_COLOR = '#fff';

export const CustomChartsLineSettings = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        ticks: {
          display: false
        }
      }
    ],
    x: {
      display: false,
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
        borderColor: BORDER_COLOR
      }
    },
    y: {
      grid: {
        display: DISPLAY,
        drawBorder: BORDER,
        drawOnChartArea: CHART_AREA,
        drawTicks: false,
        borderColor: BORDER_COLOR,
        color: BORDER_COLOR
      },
      ticks: {
        color: BORDER_COLOR,
        // Include a dollar sign in the ticks
        callback: (value /* , index, values */) => {
          return `0${value}`;
        }
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom'
    }
  }
};
