import React from "react";
import {Grid} from "@mui/material";
import {ReportsBody} from "./reports-body/ReportsBody";
import {ReportsSidebar} from "./reports-sidebar/ReportsSidebar";

export function ReportsComponent(props) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={5} lg={4} xl={3}>
				<ReportsSidebar/>
			</Grid>

			<Grid item xs={12} md={7} lg={8} xl={9}>
				<ReportsBody/>
			</Grid>
		</Grid>
	)
}
