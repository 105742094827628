import React from "react";
import {Box, Grid} from "@mui/material";
import {AwardsActions} from "./AwardsActions";


export function AwardsBody() {
	return (
		<Box sx={{flexGrow: 1}}>
			<div className={'section'}>
				<div className={'section-title text-left'}>
					<span className={'label'}/>
					<div className={'divider primary'}/>
				</div>
			</div>
			<div className={''}>
				<Grid container spacing={2}>
					<Grid item xs={12} xl={9}>
						Awards Body
					</Grid>

					<Grid item xs={12} xl={3} className={'reports'}>
						<AwardsActions/>
					</Grid>

				</Grid>
			</div>
		</Box>
	)
}
