import React from "react";
import {Grid} from "@mui/material";
import {MessengerSidebar} from "./messenger-sidebar/MessengerSidebar";
import {MessengerBody} from "./messenger-body/MessengerBody";

export function MessengerComponent(props) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={5} lg={4} xl={3}>
				<MessengerSidebar/>
			</Grid>

			<Grid item xs={12} md={7} lg={8} xl={9}>
				<MessengerBody/>
			</Grid>
		</Grid>
	)
}
