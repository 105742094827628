import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import {DashboardGraphicalReport} from "./DashboardGraphicalReport";
import {DashboardReports} from "./DashboardReports";


export function DashboardBody() {
	return (
		<Box className={'DashboardBody'}>
			<div className={'section'}>
				<div className={'section-title text-left'}>
					<span className={'label'}>PERFORMANCE: </span> last 30 days | <span className={'label'}>DATE RANGE: </span> last 30 days
					<div className={'divider primary'}/>
				</div>
			</div>
			<div className={'tips'}>
				<Grid container spacing={{xs: 2, md:4}}>
					<Grid item xs={12} xl={8} zeroMinWidth>
						<h3 className={'text-left section-title'}>Analytics</h3>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6} lg={4} className={'tips-item'}>
								<AttachMoneyOutlinedIcon/>
								<Typography variant={'h3'} color={'primary'} className={'tips-label'}>AVERAGE TIP</Typography>
								<Typography variant={'h2'} className={"tips-value"}>$0.75</Typography>
							</Grid>

							<Grid item xs={12} md={6} lg={4} className={'tips-item'}>
								<AccountBalanceWalletIcon/>
								<Typography variant={'h3'} color={'primary'} className={'tips-label'}>TOTAL TIPS</Typography>
								<Typography variant={'h2'} className={"tips-value"}>$2,195.00</Typography>
							</Grid>

							<Grid item xs={12} md={6} lg={4} className={'tips-item'}>
								<DateRangeOutlinedIcon/>
								<Typography variant={'h3'} color={'primary'} className={'tips-label'}>TIPS / EVENT</Typography>
								<Typography variant={'h2'} className={"tips-value"}>$89.65</Typography>
							</Grid>
						</Grid>

						<DashboardGraphicalReport/>

					</Grid>

					<Grid item xs={12} xl={4} className={'reports'}>
						<DashboardReports/>
					</Grid>

				</Grid>
			</div>
		</Box>
	)
}
