import Calendar from 'react-awesome-calendar';
import './SidebarCalendar.scss'

const events = [{
	id: 1,
	color: '#ff8830',
	from: '2021-05-02T18:00:00+00:00',
	to: '2021-05-05T19:00:00+00:00',
	title: 'This is an event'
}, {
	id: 2,
	color: '#ff8830',
	from: '2019-05-01T13:00:00+00:00',
	to: '2019-05-05T14:00:00+00:00',
	title: 'This is another event'
}, {
	id: 3,
	color: '#ff8830',
	from: '2019-05-05T13:00:00+00:00',
	to: '2019-05-05T20:00:00+00:00',
	title: 'This is also another event'
}];

export function DashboardSidebarCalendar() {
	return (
		<div className={'performance-calendar'}>
			<h5 className={'section-title'}>Date Range</h5>
			<Calendar
				defaultView="month"
				events={events}
			/>
		</div>
	);
}
