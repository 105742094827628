import React from 'react';
import {Line} from 'react-chartjs-2';
import {CustomChartsLineSettings} from "../../../components/custom-charts/CustomChartsSettings";

const data = {
	labels: ['08', '07', '06', '05', '04', '03', '02', '01'],
	datasets: [
		{
			label: 'Cap score',
			data: [5, 3, 6, 3, 7, 5, 8, 5],
			fill: false,
			backgroundColor: 'rgb(255,136,48)',
			borderColor: 'rgba(255,136,48,0.7)',
			cubicInterpolationMode: 'monotone',
			tension: 0.4
		},
		{
			label: 'Pick Coins',
			data: [2, 4.5, 3, 4, 1, 4, 7, 8],
			fill: false,
			backgroundColor: 'rgb(54, 162, 235)',
			borderColor: 'rgba(54, 162, 235, 0.7)',
			cubicInterpolationMode: 'monotone',
			tension: 0.4
		},
	],
};

const options = {
	...CustomChartsLineSettings
	// CustomChartsLineSettings,
	// plugins: {
	// 	label: {
	// 		position: 'bottom',
	// 	}
	// },
};

export function DashboardGraphicalReport() {
	return (
		<>
			<h3 className={'text-left section-title'}>Graphical Reports</h3>
			<div className={'graphical-report-container'}>
				<Line data={data} options={options} type={'line'}/>
			</div>
		</>
	)
}
