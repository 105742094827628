import React from 'react';
import { Container, Grid } from '@mui/material';
import { Route, Routes } from 'react-router';
import { DashboardSidebar } from './dashboard-sidebar/DashboardSidebar';
import { DashboardBody } from './dashboard-body/DashboardBody';
import { DashboardHeader } from './dashboard-header/DashboardHeader';
import { ScheduleComponent } from '../schedule/ScheduleComponent';
import { MessengerComponent } from '../messenger/MessengerComponent';
import { ReportsComponent } from '../reports/ReportsComponent';
import { AwardsComponent } from '../awards/AwardsComponent';
import { FandomComponent } from '../fandom/ReportsComponent';

export function DashboardComponent(props) {
  // const { path } = useRouteMatch();
  const path = '/dashboard';
  return (
    <div className="Dashboard">
      <div
        style={{
          backgroundImage: "url('/images/back.jpg')",
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          top: 0,
          width: '100%',
          height: '100%',
          position: 'fixed',
          zIndex: -1,
          opacity: 0.4
        }}
      />
      <DashboardHeader />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} lg={4} xl={3}>
            <DashboardSidebar />
          </Grid>

          <Grid item xs={12} md={7} lg={8} xl={9}>
            <DashboardBody />
          </Grid>
        </Grid>
        {/* <Routes>
          <Route exact path={path}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <DashboardSidebar />
              </Grid>

              <Grid item xs={12} md={7} lg={8} xl={9}>
                <DashboardBody />
              </Grid>
            </Grid>
          </Route>

          <Route exact path={`${path}/home`}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <DashboardSidebar />
              </Grid>

              <Grid item xs={12} md={7} lg={8} xl={9}>
                <DashboardBody />
              </Grid>
            </Grid>
          </Route>

          <Route exact path={`${path}/schedule`}>
            <ScheduleComponent />
          </Route>

          <Route exact path={`${path}/messenger`}>
            <MessengerComponent />
          </Route>

          <Route exact path={`${path}/reports`}>
            <ReportsComponent />
          </Route>

          <Route exact path={`${path}/awards`}>
            <AwardsComponent />
          </Route>

          <Route exact path={`${path}/fandom`}>
            <FandomComponent />
          </Route>
        </Routes> */}
      </Container>
    </div>
  );
}
