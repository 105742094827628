import {Avatar, Button, MobileStepper, Typography} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import React from "react";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import {BREAKPOINTS} from "../../../const/Breakpoints";
import {primaryColor, theme} from "../../../const/Theme";
import SwiperCore, {Pagination, A11y, Navigation} from "swiper";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/navigation/navigation.scss";

// install Swiper modules
 SwiperCore.use([Pagination, A11y, Navigation]);

export function DashboardReports() {
	const percentage = 75;
	const percentage2 = 44;
	const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)
	const progressStyles = buildStyles({
		rotation: 0.5,
		strokeLinecap: 'butt',
		textSize: '16px',
		pathTransitionDuration: 0.5,
		// pathTransition: 'none',

		// Colors
		pathColor: primaryColor,
		textColor: '#fff',
		trailColor: 'transparent',
		backgroundColor: '#ff8830',
	})

	const steps = [
		{
			title: "TIP SHARES",
			description: "Wow! You are making a real impact. Keep up the great work!",
			data: [
				<CircularProgressbarWithChildren value={percentage} styles={progressStyles} strokeWidth={15} key={1}>
					<div className={'h3'}>
						{percentage}%
					</div>
				</CircularProgressbarWithChildren>
			],
			clients:
				<div className={'swiper-container'}>
					<Swiper
						slidesPerView={2} spaceBetween={8}
						onSlideChange={() => console.log('slide change')}
						onSwiper={(swiper) => console.log(swiper)}
						navigation={{
							prevEl: navigationPrevRef.current,
							nextEl: navigationNextRef.current,
						}}
						pagination={{
							clickable: true,
							dynamicBullets: true
						}}
						breakpoints={{
							[BREAKPOINTS.sm]: {
								"slidesPerView": 3,
								"spaceBetween": 20
							},
							[BREAKPOINTS.lg]: {
								"slidesPerView": 6,
								"spaceBetween": 40
							},
							[BREAKPOINTS.xl]: {
								"slidesPerView": 3,
								"spaceBetween": 40
							}
						}}
						onBeforeInit={(swiper) => {
							swiper.params.navigation.prevEl = navigationPrevRef.current;
							swiper.params.navigation.nextEl = navigationNextRef.current;
							swiper.el.closest('.swiper-container').append(navigationPrevRef.current, navigationNextRef.current);
						}}
					>
						<SwiperSlide className={'h-auto'}>
							<div className={'clients-item'}>
								<p className={'client-name'}>Unicef</p>
								<Avatar src={'/images/logos/unicef.png'} alt={'unicef'}/>
								<p className={'client-percentage'}>15%</p>
							</div>
						</SwiperSlide>
						<SwiperSlide className={'h-auto'}>
							<div className={'clients-item'}>
								<p className={'client-name'}>Tweet Drive Foundation</p>
								<Avatar src={'/images/logos/stjudehospital.png'} alt={'unicef'}/>
								<p className={'client-percentage'}>20%</p>
							</div>
						</SwiperSlide>
						<SwiperSlide className={'h-auto'}>
							<div className={'clients-item'}>
								<p className={'client-name'}>St. Jude Hospital</p>
								<Avatar src={'/images/logos/stjudehospital.png'} alt={'t. Jude Hospital'}/>
								<p className={'client-percentage'}>20%</p>
							</div>
						</SwiperSlide>
						<SwiperSlide className={'h-auto'}>
							a
						</SwiperSlide>
						<SwiperSlide className={'h-auto'}>
							b
						</SwiperSlide>
						<div className="swiper-button-prev" ref={navigationPrevRef}>
							<Button variant={'text'} type={'button'}><KeyboardArrowLeft/></Button>
						</div>
						<div className="swiper-button-next" ref={navigationNextRef}>
							<Button variant={'text'} type={'button'}><KeyboardArrowRight/></Button>
						</div>
					</Swiper>
				</div>
		},

		{
			title: "Another Slide",
			description: "Test",
			data: [
				<CircularProgressbarWithChildren value={percentage2} styles={progressStyles} strokeWidth={15} key={2}>
					<div className={'h3'}>
						{percentage2}%
					</div>
				</CircularProgressbarWithChildren>
			],
			clients:
				<div className={'swiper-container'}>
					<div className={'swiper-container'}>
						<Swiper
							slidesPerView={2} spaceBetween={8}
							onSlideChange={() => console.log('slide change')}
							onSwiper={(swiper) => console.log(swiper)}
							navigation={{
								prevEl: navigationPrevRef.current,
								nextEl: navigationNextRef.current,
							}}
							pagination={{
								clickable: true,
								dynamicBullets: true
							}}
							breakpoints={{
								[BREAKPOINTS.sm]: {
									"slidesPerView": 3,
									"spaceBetween": 20
								},
								[BREAKPOINTS.lg]: {
									"slidesPerView": 6,
									"spaceBetween": 40
								},
								[BREAKPOINTS.xl]: {
									"slidesPerView": 3,
									"spaceBetween": 40
								}
							}}
							onBeforeInit={(swiper) => {
								swiper.params.navigation.prevEl = navigationPrevRef.current;
								swiper.params.navigation.nextEl = navigationNextRef.current;
								swiper.el.closest('.swiper-container').append(navigationPrevRef.current, navigationNextRef.current);
							}}
						>
							<SwiperSlide className={'h-auto'}>
								<div className={'clients-item'}>
									<p className={'client-name'}>Unicef</p>
									<Avatar src={'/images/logos/unicef.png'} alt={'unicef'}/>
									<p className={'client-percentage'}>15%</p>
								</div>
							</SwiperSlide>
							<SwiperSlide className={'h-auto'}>
								<div className={'clients-item'}>
									<p className={'client-name'}>Tweet Drive Foundation</p>
									<Avatar src={'/images/logos/stjudehospital.png'} alt={'unicef'}/>
									<p className={'client-percentage'}>20%</p>
								</div>
							</SwiperSlide>
							<SwiperSlide className={'h-auto'}>
								<div className={'clients-item'}>
									<p className={'client-name'}>St. Jude Hospital</p>
									<Avatar src={'/images/logos/stjudehospital.png'} alt={'t. Jude Hospital'}/>
									<p className={'client-percentage'}>20%</p>
								</div>
							</SwiperSlide>
							<div className="swiper-button-prev" ref={navigationPrevRef}>
								<Button variant={'text'} type={'button'}><KeyboardArrowLeft/></Button>
							</div>
							<div className="swiper-button-next" ref={navigationNextRef}>
								<Button variant={'text'} type={'button'}><KeyboardArrowRight/></Button>
							</div>
						</Swiper>
					</div>
				</div>
		},
	];
	const [activeStep, setActiveStep] = React.useState(0);
	const maxSteps = steps.length;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<>
			<h3 className={'text-left section-title'}>Reports</h3>
			<div className={'reports-container'}>
				<div className={'stepper-container'}>
					<MobileStepper
						className={'bg-transparent variant-text-hidden'}
						variant="dots"
						steps={maxSteps}
						position="static"
						activeStep={activeStep}
						nextButton={
							<Button
								size="small"
								onClick={handleNext}
								disabled={activeStep === maxSteps - 1}
							>
								{theme.direction === "rtl" ? (
									<KeyboardArrowLeft/>
								) : (
									<KeyboardArrowRight/>
								)}
							</Button>
						}
						backButton={
							<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
								{theme.direction === "rtl" ? (
									<KeyboardArrowRight/>
								) : (
									<KeyboardArrowLeft/>
								)}
							</Button>
						}
					/>
					<h3 className={'stepper-title section-title text-primary text-center'}>{steps[activeStep].title}</h3>
					<div className={'stepper-body'}>
						<div className={'stepper-description'}>
							<Typography variant={'h4'} component={'p'}
							            className={'stepper-description'}> {steps[activeStep].description}</Typography>
						</div>
						<div className={'stepper-progress-value'}>
							{steps[activeStep].data}
							<div className={'stepper-value'}>
								{steps[activeStep].value}
							</div>
						</div>
						{steps[activeStep].clients}
					</div>
				</div>
			</div>
		</>
	)
}
