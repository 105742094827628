import * as React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Avatar, ListItemAvatar, ListItemText } from '@mui/material';
// import {useRouteMatch} from "react-router";
import { NavLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import { AccountCircle, Logout } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import ImageIcon from '@mui/icons-material/Image';
import UserContext from '../../../contexts/UserContext/UserContext';

const navigation = [
  { link: '/home', name: 'Dashboard' },
  { link: '/schedule', name: 'Schedule' },
  { link: '/messenger', name: 'Messenger' },
  { link: '/reports', name: 'Reports' },
  { link: '/awards', name: 'Awards' },
  { link: '/fandom', name: 'Fandom' }
];

export function DashboardHeader() {
  const { logout } = React.useContext(UserContext);
  // let { url } = useRouteMatch();
  const url = '/dashboard';
  const [stateAnchor, setStateAnchor] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorProfileEl, setAnchorProfileEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [stageName, setStageName] = useState('STAGENAME');
  const isMenuOpen = Boolean(anchorEl);
  const isProfileMenuOpen = Boolean(anchorProfileEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleNotificationsMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotificationsMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = event => {
    setAnchorProfileEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorProfileEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    handleNotificationsMenuClose();
    handleProfileMenuClose();
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setStateAnchor(open);
  };

  const logo = (
    <div className="brand">
      <img src="/images/logo.png" alt="logo" loading="lazy" />
    </div>
  );

  const logoVariant = (
    <div className="brand">
      <img src="/images/pjl.png" alt="logo" loading="lazy" />
    </div>
  );

  const headerNavigationMenu = (
    <ul>
      {navigation.map((menuItem, index) => {
        return (
          <MenuItem key={`menu-item-${index}`}>
            <NavLink to={`${url}${menuItem.link}`} className="nav-link" activeClassName="selected">
              {menuItem.name}
            </NavLink>
          </MenuItem>
        );
      })}
    </ul>
  );
  const menuId = 'primary-search-account-menu';
  const renderNotificationsMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <li className="menu-header text-center">
        <strong>Notifications</strong>
      </li>
      <MenuItem onClick={handleMenuClose}>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Notification 1" secondary="Jan 9, 2014" />
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Notification 2" secondary="Jan 9, 2014" />
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <ListItemAvatar>
          <Avatar>
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Notification 3" secondary="Jan 9, 2014" />
      </MenuItem>
      <MenuItem className="justify-content-center text-primary" onClick={handleMenuClose}>
        See All
      </MenuItem>
    </Menu>
  );
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = [
    <MenuItem onClick={handleMenuClose} key="1">
      <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
        <Badge badgeContent={17} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <span>Notifications</span>
    </MenuItem>,
    <MenuItem onClick={handleMenuClose} key="2">
      <IconButton size="large" aria-label="show 4 new mails" color="inherit">
        <Badge badgeContent={4} color="error">
          <AccountCircle />
        </Badge>
      </IconButton>
      <span>Messages</span>
    </MenuItem>,
    <MenuItem onClick={handleMenuClose} key="3">
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <span>Profile</span>
    </MenuItem>,
    <MenuItem onClick={logout} key="4">
      <IconButton size="large" color="inherit">
        <Logout />
      </IconButton>
      <span>Logout</span>
    </MenuItem>
  ];

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorProfileEl}
      id={mobileMenuId}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <li className="menu-header text-center">
        <strong>User Options</strong>
      </li>
      {renderMobileMenu}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Drawer
        anchor="right"
        open={stateAnchor}
        onClose={toggleDrawer(stateAnchor, false)}
        className="drawer-main-navigation"
      >
        <div className="drawer-main-navigation-header">
          {logoVariant}
          <IconButton
            className="drawer-main-navigation-close"
            onClick={toggleDrawer(stateAnchor, false)}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </div>
        {headerNavigationMenu}
        {renderMobileMenu}
      </Drawer>

      <AppBar position="static" className="bg-dark main-navigation">
        <Toolbar className="justify-content-between">
          <div className="navigation-start">
            {logo}
            <div className="stage-name text-link">{stageName}</div>
          </div>

          <Box component="nav" className="navigation-center d-none d-xxl-flex">
            {headerNavigationMenu}
            {/* <DoorbellOutlined/> */}
          </Box>
          <Box component="nav" className="navigation-end">
            <IconButton
              onClick={handleNotificationsMenuOpen}
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              className="d-none d-sm-flex me-3"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <MenuItem onClick={handleProfileMenuOpen}>
              <ListItemAvatar className="avatar-holder me-3">
                <Avatar alt="Remy Sharp" src="https://i.pravatar.cc/300" className="avatar-image" />
                <span className="avatar-name text-primary d-none d-sm-flex">Jeffrey</span>
              </ListItemAvatar>
            </MenuItem>

            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(stateAnchor, true)}
              className="btn-open-drawer d-xxl-none"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {/* <Box sx={{display: {xs: 'flex', md: 'none'}}}> */}
          {/*	<IconButton size="large" */}
          {/*	            aria-label="show more" */}
          {/*	            aria-controls={mobileMenuId} */}
          {/*	            aria-haspopup="true" */}
          {/*	            onClick={handleMobileMenuOpen} */}
          {/*	            color="inherit" */}
          {/*	> */}
          {/*		Stage Name */}
          {/*	</IconButton> */}
          {/* </Box> */}
        </Toolbar>
      </AppBar>
      {/* {renderMobileMenu} */}
      {renderNotificationsMenu}
      {renderProfileMenu}
    </Box>
  );
}
