import React from "react";
import {Grid} from "@mui/material";
import {ScheduleSidebar} from "./schedule-sidebar/ScheduleSidebar";
import {ScheduleBody} from "./schedule-body/ScheduleBody";

export function ScheduleComponent(props) {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={5} lg={4} xl={3}>
				<ScheduleSidebar/>
			</Grid>

			<Grid item xs={12} md={7} lg={8} xl={9}>
				<ScheduleBody/>
			</Grid>
		</Grid>
	)
}
